import { Button, Grid, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'tabler-icons-react';

const WhyWeExist = ({ ctaNavigateLink }: { ctaNavigateLink: string }) => {
    const navigate = useNavigate();

    return (
        <>
            <Grid>
                <Grid.Col md={12}>
                    <Stack>
                        <Text
                            component="h1"
                            sx={{
                                fontSize: "1.5em",
                                lineHeight: 1,
                            }}
                        >
                            Why We Exist
                        </Text>
                        <Text component="h2">
                            Bad experiences and horrible offers
                        </Text>
                    </Stack>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Grid>
                        <Grid.Col md={6}>
                            <Text>
                                We understand that there are some
                                agents out there that don't have
                                your best interests at heart and are
                                more interested in making money than
                                finding the perfect home for you.
                            </Text>
                            <br />
                            <Group spacing={3} position="left">
                                <Text>
                                    They will share your phone
                                    number to multiple other agents
                                    and your phone will blow up with
                                    messages. On Propmoth , all
                                    contact takes place in our chat,
                                    no one will get your personal
                                    information from us.
                                </Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Text>
                                Agents often keep certain properties
                                away from clients because there's
                                not enough commission to be made,
                                even though those properties would
                                be the perfect fit.
                            </Text>
                            <br />
                            <Text>
                                Simply put, good agents will find
                                properties that fit your needs and
                                bad agents will be driven out of the
                                market, creating the perfect
                                environment for serious clients.
                            </Text>
                        </Grid.Col>
                        <Grid.Col>
                            <Group>
                                <Button
                                    radius="xl"
                                    onClick={() =>
                                        navigate(ctaNavigateLink)
                                    }
                                >
                                    <Group
                                        spacing={4}
                                        align="center"
                                    >
                                        <Text fz="md">
                                            Start for free{" "}
                                        </Text>
                                        <ChevronRight width={14} />
                                    </Group>
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
            </Grid>
        </>
    );
};

export default WhyWeExist;