//@ts-nocheck
import {
    ActionIcon,
    AspectRatio,
    Badge,
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Container,
    Divider,
    FileButton,
    FileInput,
    Flex,
    Grid,
    Group,
    Image,
    Indicator,
    LoadingOverlay,
    Modal,
    NumberInput,
    Select,
    Stack,
    Switch,
    Text,
    Textarea,
    TextInput,
    Tooltip,
    UnstyledButton,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useScrollIntoView } from "@mantine/hooks";
import { IconCoin } from "@tabler/icons-react";
import { useGetConfigs } from "api/configAPI";
import { useListingAPI } from "api/useListingAPI";
import axios from "axios";
import { LightBox } from "components/LightBox";
import { TokensModal } from "components/Modals/TokensModal";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    CONFIG_KEYS,
    PROPERTY_TITLE_TYPE_OPTIONS,
    TENURE_OPTIONS,
    USER_STATUS,
    USER_TYPE,
} from "helpers/constants";
import { convertFileToPreview, formatEmbedObj, getTagDisplayText, selectFilter } from "helpers/utility";
import { Permission, useAuth } from "hooks/useAuth";
import { useUpload } from "hooks/useUpload";
import { useWallet } from "hooks/useWallet";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
    AlertTriangle,
    ArrowLeft,
    Bath,
    Bed,
    Files,
    InfoCircle,
    Photo,
    Plus,
    Tex,
    Trash,
} from "tabler-icons-react";
import ImageInput from "./OfferForm/Components/ImageInput";
import VideoEmbedInput from "./OfferForm/Components/VideoEmbedInput";
import AccountPendingModal from "components/Modals/AccountPendingModal";
import { useCanOffer } from "hooks/useCanOffer";
import BedroomsInput from "./PropRequestForm/components/BedroomsInput";
import BathroomsInput from "./PropRequestForm/components/BathroomsInput";

enum OFFER_STEPS {
    FORM,
    PREVIEW,
}

export default function CreateOffer() {
    const { canOffer, canOfferisLoading } = useCanOffer()
    const {
        useCreateListingAndOffer,
        useGetOnePropRequests,
        useGetTags,
        useScrape,
        useGetMyListings,
        useCreateOffer
    } = useListingAPI();
    const navigate = useNavigate();
    let { id } = useParams();
    const { user } = useAuth(Permission.USER_ONLY);
    const {
        data: existingListings,
        isLoading: existingListingsIsLoading,
        error: existingListingsError,
        refetch: refetchExistingListings
    } = useGetMyListings({
        id: user.id.toString(),
        pagination: {}
    });

    const { walletData, walletIsLoading, walletError } = useWallet(
        user.userType === USER_TYPE.AGENT ? user.id.toString() : null
    );

    const [tagForm, setTagForm] = useState<any>({});
    const [files, setFiles] = useState<File[]>([]);

    const [existingListingImages, setExistingListingImages] = useState([])
    const [selectedExistingListing, setSelectedExistingListing] = useState("")
    const [selectedExistingListingErr, setSelectedExistingListingErr] = useState(false)

    const [importImages, setImportImages] = useState<string[]>([]);
    const [step, setStep] = useState(OFFER_STEPS.FORM);
    const [base64Preview, setBase64Preview] = useState<any[]>([]);
    const [createLoading, setCreateLoading] = useState(false);
    const [offerCheck, setOfferCheck] = useState(false);
    const [draftPopup, setDraftPopup] = useState<any>({});
    const [purchaseTokensPopup, setPurchaseTokensPopup] = useState(false);
    const [states, setStates] = useState([]);
    const [imagesError, setImageError] = useState(false)
    const [tagsHasError, setTagsHasError] = useState(false)
    const { scrollIntoView, targetRef } =
        useScrollIntoView<HTMLDivElement, HTMLDivElement>();

    const [urlArr, setUrlArr] = useState<any[]>([]);

    const [importLink, setImportLink] = useState("");
    const [importError, setImportError] = useState<string>("");
    const [importIsLoading, setImportIsLoading] = useState(false);

    const [showLightbox, setShowLightbox] = useState(false);
    const [isPrivate, setIsPrivate] = useState(true)

    const createListingAndOffer = useCreateListingAndOffer();
    const createOffer = useCreateOffer()
    const scrape = useScrape();

    const { uploadMutation } = useUpload();
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    // const propertyTypesData = require("../assets/propertyTypes.json");

    const {
        data: requestData,
        isLoading: requestIsLoading,
        error: requestError,
    } = useGetOnePropRequests(id as string);

    const releventListings = useMemo(() => {
        console.log("existingListings", existingListings)

        if (!!existingListings?.data && !existingListingsIsLoading && !!requestData) {
            return existingListings.data.filter((l) => l.transactionType === requestData.transactionType).map(l => ({
                value: l.id,
                label: `${l.title} by ${l.developer}`
            }))
        }

        return []
    }, [existingListings, existingListingsIsLoading, requestData])

    const handleExistingListingSelect = (e) => {
        if (!!e) {
            e.preventDefault();
        }

        if (!selectedExistingListing) {
            setSelectedExistingListingErr(true)
            return
        }

        const listingData = existingListings?.data.find(l => l.id === selectedExistingListing)

        form.setValues({
            title: listingData.title,
            propertyType: listingData.propertyType,
            price: listingData.price,
            noOfBedrooms: listingData.noOfBedrooms,
            noOfBathrooms: listingData.noOfBathrooms,
            floorSize: listingData.floorSize,
            landSize: listingData.landSize,
            propertyTitleType: listingData.propertyTitleType,
            tenure: listingData.tenure,
            developer: listingData.developer,
            address: listingData.address,
            state: listingData.state,
            area: listingData.area,
            transactionType: listingData.transactionType,
        })

        let tagObj = {} as any
        for (const { tag, value } of listingData.tags) {
            if (tag.values.type === "select") {
                tagObj[tag.title] = {
                    ...tag,
                    value
                }
            }

            if (tag.values.type === "boolean") {
                tagObj[tag.title] = {
                    ...tag,
                    value: true
                }
            }
        }
        setTagForm({ ...tagForm, ...tagObj })
        setImportImages(listingData.imageLinks)
        if (!!listingData.images) {
            setExistingListingImages(listingData.images)
        }

        setStep(OFFER_STEPS.PREVIEW)
    }

    const handleEdit = useCallback(() => {
        if (selectedExistingListing) {
            form.reset()
            form.setFieldValue("transactionType", requestData.transactionType)
            setTagForm({})
        }

        setStep(OFFER_STEPS.FORM)
    }, [requestData, selectedExistingListing])

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    const propertyTypesData = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.PROPERTY_TYPES
            ).value;
        }

        return [];
    }, [configData]);

    const propertyTypes = useMemo(() => {
        if (!!requestData?.transactionType) {
            const propertyTypeObj = propertyTypesData.find(
                (p: any) => p.type === requestData.transactionType
            );

            if (!!propertyTypeObj) {
                return propertyTypeObj.values.map((a: any) => ({
                    value: a,
                    label: a,
                }));
            }
        }

        return [];
    }, [requestData]);

    const {
        data: tags,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();

    const form = useForm({
        initialValues: {
            title: "",
            propertyType: "",
            price: "",
            noOfBedrooms: "",
            noOfBathrooms: "",
            floorSize: "",
            landSize: "",
            propertyTitleType: "",
            tenure: "",
            developer: "",
            address: "",
            state: "",
            area: "",
            transactionType: "",
            pitch: ""
        },
        validate: {
            title: (value) => (!!value ? null : "Please fill in this field"),
            propertyType: (value) =>
                !!value ? null : "Please fill in this field",
            price: (value) => (!!value ? null : "Please fill in this field"),
            noOfBedrooms: (value) =>
                !!value ? null : "Please fill in this field",
            noOfBathrooms: (value) =>
                !!value ? null : "Please fill in this field",
            floorSize: (value) =>
                !!value ? null : "Please fill in this field",
            propertyTitleType: (value) =>
                !!value ? null : "Please fill in this field",
            tenure: (value) => (!!value ? null : "Please fill in this field"),
            developer: (value) =>
                !!value ? null : "Please fill in this field",
            address: (value) => (!!value ? null : "Please fill in this field"),
        },
    });

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    const blocks = useMemo(() => {
        return [
            { title: "Bedroom(s)", key: form.values.noOfBedrooms },
            { title: "Bathroom(s)", key: form.values.noOfBathrooms },
            { title: "Floor Size", key: `${form.values.floorSize} sqft` },
            { title: "Land Size", key: form.values.landSize },
            {
                title: "Property Title Type",
                key: form.values.propertyTitleType,
            },
            { title: "Tenure", key: form.values.tenure },
            { title: "Developer", key: form.values.developer },
        ];
    }, [form.values]);

    // useEffect(() => {
    //     let draft = {
    //         propRequestId: id,
    //         form: form.values,
    //         tags: tagForm,
    //         base64Preview,
    //     };

    //     const formDirty = form.isDirty();

    //     if (
    //         formDirty ||
    //         Object.keys(tagForm).length > 0 ||
    //         base64Preview.length > 0
    //     ) {
    //         sessionStorage.setItem("offer_draft", JSON.stringify(draft));
    //     }
    // }, [form.values, tagForm, base64Preview]);

    useEffect(() => {
        const draft = sessionStorage.getItem("offer_draft");

        if (!!draft) {
            const parsed = JSON.parse(draft);
            setDraftPopup(parsed);
        }
    }, []);

    const hasDraft = useMemo(() => {
        const hasKeys = Object.keys(draftPopup).length > 0;
        if (!!hasKeys && draftPopup.propRequestId === (id as string)) {
            const hasNotEmptyValues =
                Object.values(draftPopup.form).filter((v) => !!v).length > 0;
            const hasImages = draftPopup.base64Preview?.length > 0;
            const hasTagsValues =
                Object.values(draftPopup.tags).filter((t: any) => !!t.value)
                    .length > 0;

            return hasNotEmptyValues && hasImages && hasTagsValues;
        }
        return false;
    }, [draftPopup]);

    const removeDraft = () => {
        setDraftPopup({});
        sessionStorage.removeItem("offer_draft");
    };

    const importDraft = async () => {
        if (!!draftPopup.form) {
            form.setValues(draftPopup.form);
        }
        if (Object.keys(draftPopup.tags).length > 0) {
            setTagForm(draftPopup.tags);
        }

        if (draftPopup.base64Preview.length > 0) {
            setBase64Preview(draftPopup.base64Preview);

            let base64Decoded = [];
            for (const image of draftPopup.base64Preview) {
                const fileData = await fetch(image.base64);
                const blob = await fileData.blob();

                base64Decoded.push(new File([blob], image.name));
            }
            setFiles(base64Decoded);
        }

        sessionStorage.removeItem("offer_draft");
        setDraftPopup({});
    };

    useEffect(() => {
        console.log({ base64Preview })
    }, [base64Preview])

    useEffect(() => {
        if (!!tags && !!requestData) {

            const releventTags = tags.filter((t: any) => {
                return t.values.transactionType.includes(
                    requestData.transactionType
                );
            });

            if (releventTags.length > 0) {
                let tagsInput = {} as any;
                releventTags.forEach((tag: any) => {
                    let defaultValue = false

                    if (tag.values.type === "select") {
                        defaultValue = ""
                    }

                    tagsInput[tag.title] = { ...tag, value: defaultValue };
                });

                setTagForm(tagsInput);

                // setFormErrors({
                //     ...formErrors,
                //     tags: tagErr
                // })
                // setTagsError()
            }
        }
    }, [tags, requestData]);

    const tagsOptionOnChange = (
        title: string,
        value: string | null | boolean
    ) => {
        if (value === null) {
            return;
        }

        const editing = tagForm[`${title}`];
        setTagForm((prev: any) => ({
            ...tagForm,
            [`${title}`]: {
                ...editing,
                value,
            },
        }));
    };

    const generateTagsForm = useCallback(() => {
        const selects = [] as any[];
        const booleans = [] as any[];

        Object.values(tagForm).forEach((tag: any) => {
            if (tag.values.type === "boolean") {
                booleans.push(tag);
            }

            if (tag.values.type === "select") {
                selects.push(tag);
            }
        });

        return {
            booleans: booleans.map((tag: any) => (
                <Grid.Col
                    md={3}
                    sm={6}
                    xs={6}
                >
                    <Flex sx={{ flexDirection: "column" }}>
                        <Text
                            sx={{
                                display: "inline-block",
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                color: "#212529",
                                wordBreak: "break-word",
                                cursor: "default",
                            }}
                        >
                            {tag.title}
                        </Text>
                        <Switch
                            pt={2}
                            checked={tag.value}
                            onChange={(event) =>
                                tagsOptionOnChange(
                                    tag.title,
                                    event.currentTarget.checked
                                )
                            }
                        />
                    </Flex>
                </Grid.Col>
            )),
            selects: selects.map((tag: any) => (
                <React.Fragment key={`tag-${tag.title}`}>
                    <Select
                        label={tag.title}
                        placeholder="Pick one"
                        data={tag.values.options}
                        value={tag.value}
                        onChange={(v) =>
                            tagsOptionOnChange(tag.title, v)
                        }
                        error={tagsHasError && !tag.value ? "Please fill in this field" : null}
                    // error={formErrors.tags[tag.title] ? "Please fill in this field" : null}
                    />
                </React.Fragment>
            ))
        };
    }, [tagForm, tagsHasError]);

    const allTagsReady = () => {
        const fillableInputs = Object.values(tagForm).filter(
            (t: any) => t.values.type !== "boolean"
        );

        const total = fillableInputs.length;
        const notEmptyCount = fillableInputs.filter(
            (t: any) => !!t.value
        ).length;

        return total === notEmptyCount;
    };

    const isReadyForPreview = useMemo(() => {
        const isValid = form.isValid();

        if (form.isDirty()) {
            form.validate();
        }

        return (
            !!allTagsReady() &&
            isValid &&
            (base64Preview.length > 0 || importImages.length > 0)
        );
    }, [
        tagForm,
        form.values,
        importImages,
        base64Preview
    ]);

    useEffect(() => {
        if (!!requestData) {
            form.setFieldValue("transactionType", requestData.transactionType);
        }
    }, [requestData]);

    const mergePreviews = useCallback(() => {
        const formattedImport = importImages.map((e, i) => ({
            url: e,
            id: `importImages-${i}`
        }))

        const formattedBase64 = base64Preview.map((b, i) => ({
            url: b.base64,
            id: `base64Preview-${i}`
        }))

        let formattedExistingImages = []
        if (existingListingImages.length > 0) {
            formattedExistingImages = existingListingImages.map((li, i) => ({
                url: li.url ?? "/img-placeholder.jpeg",
                id: `existing-${i}`
            }))
        }


        return [...formattedBase64, ...formattedImport, ...formattedExistingImages]
    }, [base64Preview, importImages, existingListingImages])

    const translateToTags = () => {
        if (!tagForm) {
            return <></>;
        }

        const badgeArr = Object.values(tagForm)
            .map((tag: any) => {
                if (tag.values.type === "boolean" && !!tag.value) {
                    return {
                        title: tag.title,
                        color: tag.badgeColor,
                        background: tag.badgeBackground,
                    };
                }

                if (tag.values.type === "select") {
                    return {
                        title: getTagDisplayText(tag, tag.value),
                        color: tag.badgeColor,
                        background: tag.badgeBackground,
                    };
                }

                return null;
            })
            .filter((t: any) => !!t);

        return (
            <Group>
                {badgeArr.map((b: any) => (
                    <Badge
                        sx={{ backgroundColor: b.background, color: b.color }}
                    >
                        {b?.title}
                    </Badge>
                ))}
            </Group>
        );
    };

    const handleCreateListingAndOffer = async () => {
        try {
            let tagArr = [];
            let imgArr = [];
            let mediaArr = [];

            for (const tag of Object.values(tagForm)) {
                const tagValue = tag as any;
                let tagObj = {
                    tagId: tagValue.id,
                } as any;

                if (tagValue.values.type === "boolean" && !!tagValue.value) {
                    tagObj["value"] = tagValue.title;
                }

                if (tagValue.values.type === "select") {
                    tagObj["value"] = getTagDisplayText(tag, tagValue.value);
                }

                if (!!tagObj.value) {
                    tagArr.push(tagObj);
                }
            }

            if (urlArr.length > 0) {
                mediaArr = formatEmbedObj(urlArr)
            }

            let offerObj = {
                ...form.values,
                floorSize: parseInt(form.values.floorSize) ?? 0,
                landSize: parseInt(form.values.landSize) ?? 0,
                tags: tagArr,
                media: mediaArr,
                // uploads: imgArr,
                propertyRequest: parseInt(id as string),
                imageLinks: importImages,
                status: isPrivate ? 'hidden' : 'pending',
            } as any;

            if (!importError) {
                offerObj.importLink = importLink;
            }

            const listing = await createListingAndOffer.mutateAsync(offerObj);

            if (files.length > 0) {
                for (const img of files) {
                    const uploadId = await uploadMutation.mutateAsync({
                        file: img,
                        path: `listings/${listing.id}`,
                        ref: "api::listing.listing",
                        refId: listing.id,
                        field: "images",
                    });

                    imgArr.push(uploadId[0].id);
                }
            }

            // if(importImages.length > 0){
            //     for (const img of importImages) {
            //         const myImage = await fetch(img);

            //         const myBlob = await myImage.blob();
            //         const uploadId = await uploadMutation.mutateAsync({
            //             file: myBlob,
            //             name: `${listing.id}-listing-image`,
            //             path: `listings/${listing.id}`,
            //             ref: "api::listing.listing",
            //             refId: listing.id,
            //             field: "images",
            //         });

            //         console.log('uploadId',uploadId)

            //         imgArr.push(uploadId[0].id);
            //     }
            // }

            sessionStorage.removeItem("offer_draft");
            setCreateLoading(false);
            navigate("/profile");
        } catch (e: any) {
            // console.log();
            if (e?.response?.data?.error?.message === "Insufficient tokens") {
                setPurchaseTokensPopup(true);
            }
            setCreateLoading(false);
        }
    }

    const handleCreateOffer = async () => {
        try {
            await createOffer.mutateAsync({
                listing: parseInt(selectedExistingListing),
                propertyRequest: parseInt(id),
                pitch: form.values.pitch
            })

            sessionStorage.removeItem("offer_draft");
            setCreateLoading(false);
            navigate("/profile");
        } catch (e) {
            if (e?.response?.data?.error?.message === "Insufficient tokens") {
                setPurchaseTokensPopup(true);
            }
            setCreateLoading(false);
        }
    }

    const handleCreate = async () => {
        // if(walletData?.amount > 5 || !walletData){
        //     return
        // }
        setCreateLoading(true);
        if (!selectedExistingListing) {
            await handleCreateListingAndOffer()
            return
        } else {
            await handleCreateOffer()
            return
        }
    };

    const importLinkOnChange = (link: string) => {
        setImportLink(link);

        const propertyGuruRegex =
            /(https:\/\/www\.propertyguru\.com\.my\/property-listing\/).+/;
        const propertyGuruProjectRegex =
            /(https:\/\/www\.propertyguru\.com\.my\/property-listing\/project\/).+/;
        const iPropertyRegex =
            /^https:\/\/www\.iproperty\.com\.my\/property\/[^/]+\/[^/]+\/(sale|rent)-\d+\/$/;

        if (!!propertyGuruProjectRegex.test(link)) {
            setImportError("This link is not supported yet");
            return
        }

        if (!propertyGuruRegex && !iPropertyRegex) {
            setImportError("Please enter valid property guru or iproperty link");
        } else {
            setImportError("");
        }
        return
    };

    const onScrape = async () => {
        setImportIsLoading(true);
        try {
            if (importError.length > 0) {
                return;
            }

            const { data } = await scrape.mutateAsync({
                link: importLink,
            });

            setImportError("");
            form.setValues({
                title: data?.title ?? "",
                developer: data?.developer ?? "",
                noOfBathrooms: data?.noOfBathrooms ?? "",
                noOfBedrooms: data?.noOfBedrooms ?? "",
                price: data?.price ?? "",
                floorSize: data?.sqft ?? "",
                address: data?.address ?? "",
                propertyType: data.propertyType ?? "",
                tenure: !!data.tenure ? data.tenure.toLowerCase() : "",
                propertyTitleType: !!data.propertyTitle
                    ? data.propertyTitle.toLowerCase()
                    : "",
                state: data.addressState ?? "",
                area: data.addressArea ?? "",
            });

            if (!!data.images && data.images.length > 0) {
                setImportImages(data.images);
            }

            setImportIsLoading(false);
        } catch (e) {
            setImportIsLoading(false);
        }
    };

    const imagePreviewData = useMemo(() => {
        let preview = "";
        let imagePreviewArr = [];



        if (base64Preview.length > 0) {
            preview = base64Preview[0].base64;
            imagePreviewArr = [
                ...imagePreviewArr,
                base64Preview.map((b) => ({
                    url: b.base64,
                })),
            ];
        }
        if (importImages.length > 0) {
            preview = importImages[0];
            imagePreviewArr = [
                ...imagePreviewArr,
                importImages.map((e) => ({
                    url: e,
                })),
            ];
        }

        return {
            length: base64Preview.length + importImages.length,
            preview,
            previewArr: imagePreviewArr,
        };
    }, [base64Preview, importImages]);

    const validateForm = useCallback(() => {
        if (selectedExistingListing) {
            handleExistingListingSelect()
        }

        const isValid = form.isValid();
        let scrollToTop = false

        if (!!allTagsReady() && isValid && (base64Preview.length > 0 || importImages.length > 0)) {
            setTagsHasError(false)
            setImageError(false)
            setStep(OFFER_STEPS.PREVIEW)
            return
        }

        if (!allTagsReady()) {
            setTagsHasError(true)
            scrollToTop = true
        }

        if (base64Preview.length === 0 && importImages.length === 0) {
            setImageError(true)
        } else if (base64Preview.length > 1 || importImages.length > 1) {
            setImageError(false)
        }

        if (!!scrollToTop) {
            scrollIntoView()
        }
    }, [
        selectedExistingListing,
        base64Preview,
        importImages,
        tagForm,
        form
    ])

    useEffect(() => {
        if (base64Preview.length === 0 && importImages.length === 0) {
            setImageError(true)
        } else if (base64Preview.length > 1 || importImages.length > 1) {
            setImageError(false)
        }
    }, [
        base64Preview,
        importImages,
    ])

    return (
        <Box sx={{ height: "calc(100vh - 128px)", position: "relative", }} className="hide-scrollbar">
            {/* <AccountPendingModal
                opened={!canOffer && !canOfferisLoading}
                onClose={() => navigate("/profile")}
                title="Restricted Action"
            /> */}
            <Modal
                centered
                opened={hasDraft}
                onClose={() => removeDraft()}
                title="Continue Draft"
            >
                <Card>
                    <Stack
                        spacing={24}
                        align="center"
                    >
                        <Text
                            align="center"
                            component="h1"
                            m={0}
                            fw={700}
                            fz={18}
                        >
                            Would you like to continue <br />
                            where you left off
                        </Text>
                        <Group sx={{ width: "100%" }}>
                            <Button
                                onClick={() => removeDraft()}
                                sx={{ flexGrow: 1 }}
                                variant="outline"
                            >
                                No
                            </Button>
                            <Button
                                onClick={() => importDraft()}
                                sx={{ flexGrow: 1 }}
                            >
                                Yes
                            </Button>
                        </Group>
                    </Stack>
                </Card>
            </Modal>
            <LightBox
                showLightBox={showLightbox}
                onClose={() => setShowLightbox(false)}
                // image={formattedData.offerImages[0]?.src || ""}
                images={mergePreviews()}
            />
            <TokensModal
                opened={purchaseTokensPopup}
                onCancel={() => setPurchaseTokensPopup(false)}
                tokenAmount={2}
            />
            <Container
                size="1200px"
                py={32}
                px={16}
            >
                <LoadingOverlay
                    visible={createLoading}
                    overlayBlur={2}
                />
                <Stack spacing={24}>
                    <Group>
                        <Button variant="subtle" leftIcon={<ArrowLeft />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Group>
                    <Group
                        position="apart"
                        sx={{ flexWrap: "nowrap" }}
                    >
                        <Text
                            fz={32}
                            fw={600}
                            sx={{ flexGrow: 1 }}
                        >
                            Make Offer
                        </Text>
                        {/* <Button
                            variant="subtle"
                            onClick={() => navigate("/tokens/purchase")}
                            p={4}
                        >
                            <Flex
                                align={"center"}
                                justify={"flex-end"}
                            >
                                <Text
                                    mr={4}
                                    size={20}
                                    weight={500}
                                    sx={{ lineHeight: "30px" }}
                                >
                                    Your tokens:
                                </Text>
                                <IconCoin
                                    width={18}
                                    height={18}
                                    style={{ marginRight: "4px" }}
                                />
                                <Text
                                    mr={4}
                                    size={20}
                                    weight={500}
                                    sx={{ lineHeight: "30px" }}
                                >
                                    {walletData?.amount ?? 0}
                                </Text>
                            </Flex>
                        </Button> */}
                    </Group>
                    {requestIsLoading && !requestData ? (
                        <LoadingOverlay
                            visible={requestIsLoading}
                            overlayBlur={2}
                        />
                    ) : (
                        <>
                            <Grid>
                                <Grid.Col md={5}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            height: "100%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: "sticky",
                                                top: 0,
                                                left: 0,
                                            }}
                                            pt={16}
                                        >
                                            <Stack>
                                                {step === OFFER_STEPS.PREVIEW && (
                                                    <Text fw={700} fz={24}>Property Request</Text>
                                                )}
                                                <PropRequestCard
                                                    data={requestData ?? {}}
                                                    forAgents
                                                    hideButtons
                                                />
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Grid.Col>
                                {step === OFFER_STEPS.FORM && (
                                    <Grid.Col md={7}>
                                        <Grid
                                            pb={48}
                                            sx={{ padding: "0" }}
                                            pt={16}
                                        >
                                            <Grid.Col>
                                                {releventListings.length > 0 && (
                                                    <>
                                                        <Card shadow="sm" sx={{ border: "1px solid #BFC8C3", }}>
                                                            <Stack>
                                                                <Text fz={'xl'} fw={700}>Offer an Existing Listing</Text>
                                                                <form onSubmit={(e) => handleExistingListingSelect(e)}>
                                                                    <Flex sx={{ flexDirection: 'row', width: '100%', alignItems: 'flex-end', gap: 8 }}>
                                                                        <Select
                                                                            data={releventListings}
                                                                            label="Select Listing"
                                                                            placeholder="Pick one"
                                                                            searchable
                                                                            filter={
                                                                                selectFilter
                                                                            }
                                                                            nothingFound="No options"
                                                                            onChange={(e) => {
                                                                                setSelectedExistingListingErr(false)
                                                                                setSelectedExistingListing(e)
                                                                            }}
                                                                            sx={{ flexGrow: 1, flex: 1 }}
                                                                            value={selectedExistingListing}
                                                                        />
                                                                        <Button
                                                                            type="submit"
                                                                        >Offer</Button>
                                                                    </Flex>
                                                                </form>
                                                                {!!selectedExistingListingErr && (
                                                                    <Text color="red" mt={4} fz="sm">Please select a listing to offer</Text>
                                                                )}
                                                            </Stack>
                                                        </Card>
                                                        <Divider my={8} size="sm" label={<Text fz={'lg'} fw={700}>OR</Text>} labelPosition="center" color="#417256" />
                                                    </>
                                                )}
                                                <Card shadow="sm" sx={{ border: "1px solid #BFC8C3", }}>
                                                    <Stack>
                                                        {/* <Stack>
                                                            <Text fz={'xl'} fw={700}>Create a new listing and offer</Text>
                                                            <Group spacing={8}>
                                                                <Text
                                                                    fz={18}
                                                                    fw={700}
                                                                >
                                                                    Property Guru / iProperty Link <Text component="span" fz="sm" fw={500}>(optional)</Text>
                                                                </Text>
                                                                <Badge>BETA</Badge>
                                                            </Group>
                                                            <Stack spacing={8}>
                                                                <Group spacing={4}>
                                                                    <Text size="sm">
                                                                        paste property
                                                                        guru or iproperty link here
                                                                        to speed up the
                                                                        offer creation
                                                                        process, users
                                                                        will be able to
                                                                        see this link on
                                                                        the offer
                                                                    </Text>
                                                                    <Text size="sm">
                                                                        Click "import"
                                                                        to import data
                                                                        from property
                                                                        guru, newer
                                                                        listings may not
                                                                        work
                                                                    </Text>
                                                                </Group>
                                                                <TextInput
                                                                    radius="md"
                                                                    value={importLink}
                                                                    onChange={(e) =>
                                                                        importLinkOnChange(
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                        flex: 1,
                                                                    }}
                                                                    placeholder="https://www.propertyguru.com.my/property-listing/...."
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: 19,
                                                                    }}
                                                                >
                                                                    {importError.length >
                                                                        0 && (
                                                                            <Text
                                                                                color="red"
                                                                                align="left"
                                                                                size="xs"
                                                                            >
                                                                                {
                                                                                    importError
                                                                                }
                                                                            </Text>
                                                                        )}
                                                                </Box>
                                                            </Stack>
                                                            <Group position="right">
                                                                <Button
                                                                    disabled={
                                                                        importError.length >
                                                                        0 ||
                                                                        importLink.length ===
                                                                        0
                                                                    }
                                                                    type="submit"
                                                                    onClick={() =>
                                                                        onScrape()
                                                                    }
                                                                    loading={
                                                                        importIsLoading
                                                                    }
                                                                >
                                                                    Import
                                                                </Button>
                                                            </Group>
                                                        </Stack>
                                                        <Divider my={8} /> */}
                                                        <Grid pos="relative" ref={targetRef}>
                                                            <LoadingOverlay
                                                                visible={
                                                                    !!importIsLoading
                                                                }
                                                                overlayBlur={2}
                                                            />
                                                            <Grid.Col>
                                                                <Stack>
                                                                    <Text
                                                                        fz={18}
                                                                        fw={700}
                                                                    >
                                                                        Advanced Details
                                                                    </Text>
                                                                    <Stack>
                                                                        {generateTagsForm().selects}
                                                                        <Grid>
                                                                            {generateTagsForm().booleans}
                                                                        </Grid>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid.Col>
                                                            <Grid.Col>
                                                                <Divider my={8} />
                                                            </Grid.Col>
                                                            <Grid.Col>
                                                                <Stack>
                                                                    <Text
                                                                        fz={18}
                                                                        fw={700}
                                                                    >
                                                                        Offer Details
                                                                    </Text>
                                                                    <Stack>
                                                                        <Stack spacing={4}>
                                                                            <Switch
                                                                                label="Set listing as private?"
                                                                                pt={2}
                                                                                checked={isPrivate}
                                                                                onChange={(event) =>
                                                                                    setIsPrivate(event.currentTarget.checked)
                                                                                }
                                                                            />
                                                                            <Text color="#417256" size="sm">*This will make your listing only visible to the user you are offering to</Text>
                                                                        </Stack>
                                                                        <Select
                                                                            withAsterisk
                                                                            label="Transaction Type"
                                                                            // placeholder="Pick one"
                                                                            data={[
                                                                                "BUY",
                                                                                "RENT",
                                                                                "INVEST",
                                                                            ]}
                                                                            {...form.getInputProps(
                                                                                "transactionType"
                                                                            )}
                                                                            disabled
                                                                        />
                                                                        <TextInput
                                                                            withAsterisk
                                                                            label="Title"
                                                                            radius="md"
                                                                            {...form.getInputProps(
                                                                                "title"
                                                                            )}
                                                                        />

                                                                        <Select
                                                                            withAsterisk
                                                                            label="Property Type"
                                                                            placeholder="Pick one"
                                                                            data={
                                                                                propertyTypes
                                                                            }
                                                                            searchable
                                                                            filter={
                                                                                selectFilter
                                                                            }
                                                                            nothingFound="No options"
                                                                            {...form.getInputProps(
                                                                                "propertyType"
                                                                            )}
                                                                        />
                                                                        <TextInput
                                                                            withAsterisk
                                                                            label="Price"
                                                                            type="number"
                                                                            onWheel={(evt) => evt.currentTarget.blur()}
                                                                            radius="md"
                                                                            {...form.getInputProps(
                                                                                "price"
                                                                            )}
                                                                            icon={
                                                                                <Text>
                                                                                    RM
                                                                                </Text>
                                                                            }
                                                                        />
                                                                        <TextInput
                                                                            withAsterisk
                                                                            label="Developer"
                                                                            radius="md"
                                                                            {...form.getInputProps(
                                                                                "developer"
                                                                            )}
                                                                        />
                                                                        <Group>
                                                                            <BedroomsInput form={form} />
                                                                            <BathroomsInput form={form} />
                                                                        </Group>
                                                                        <Group>
                                                                            <TextInput
                                                                                withAsterisk
                                                                                label="Floor size"
                                                                                type="number"
                                                                                onWheel={(evt) => evt.currentTarget.blur()}
                                                                                radius="md"
                                                                                sx={{
                                                                                    flex: 1,
                                                                                }}
                                                                                {...form.getInputProps(
                                                                                    "floorSize"
                                                                                )}
                                                                                rightSection={
                                                                                    <Text
                                                                                        sx={{
                                                                                            color: "#adb5bd",
                                                                                        }}
                                                                                    >
                                                                                        Sqft
                                                                                    </Text>
                                                                                }
                                                                                rightSectionWidth={
                                                                                    45
                                                                                }
                                                                            />
                                                                            <TextInput
                                                                                label="Land size"
                                                                                type="number"
                                                                                onWheel={(evt) => evt.currentTarget.blur()}
                                                                                radius="md"
                                                                                sx={{
                                                                                    flex: 1,
                                                                                }}
                                                                                {...form.getInputProps(
                                                                                    "landSize"
                                                                                )}
                                                                                rightSection={
                                                                                    <Text
                                                                                        sx={{
                                                                                            color: "#adb5bd",
                                                                                        }}
                                                                                    >
                                                                                        Sqft
                                                                                    </Text>
                                                                                }
                                                                                rightSectionWidth={
                                                                                    45
                                                                                }
                                                                            />
                                                                        </Group>
                                                                        <Select
                                                                            withAsterisk
                                                                            label="Property Title Type"
                                                                            placeholder="Pick one"
                                                                            radius="md"
                                                                            data={
                                                                                PROPERTY_TITLE_TYPE_OPTIONS
                                                                            }
                                                                            {...form.getInputProps(
                                                                                "propertyTitleType"
                                                                            )}
                                                                        />
                                                                        <Select
                                                                            withAsterisk
                                                                            label="Tenure"
                                                                            placeholder="Pick one"
                                                                            radius="md"
                                                                            data={
                                                                                TENURE_OPTIONS
                                                                            }
                                                                            {...form.getInputProps(
                                                                                "tenure"
                                                                            )}
                                                                        />
                                                                        <Group>
                                                                            <Select
                                                                                sx={{
                                                                                    flex: 1,
                                                                                }}
                                                                                label="State"
                                                                                placeholder="Pick one"
                                                                                data={
                                                                                    states
                                                                                }
                                                                                searchable
                                                                                nothingFound="No options"
                                                                                filter={
                                                                                    selectFilter
                                                                                }
                                                                                {...form.getInputProps(
                                                                                    "state"
                                                                                )}
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    form.setFieldValue(
                                                                                        "state",
                                                                                        e ||
                                                                                        ""
                                                                                    );
                                                                                    form.setFieldValue(
                                                                                        "area",
                                                                                        ""
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <Select
                                                                                sx={{
                                                                                    flex: 1,
                                                                                }}
                                                                                label="Area"
                                                                                placeholder="Pick one"
                                                                                data={
                                                                                    !form
                                                                                        .values
                                                                                        .state
                                                                                        ? []
                                                                                        : areas
                                                                                }
                                                                                disabled={
                                                                                    !form
                                                                                        .values
                                                                                        .state
                                                                                }
                                                                                searchable
                                                                                nothingFound="No options"
                                                                                filter={
                                                                                    selectFilter
                                                                                }
                                                                                {...form.getInputProps(
                                                                                    "area"
                                                                                )}
                                                                            />
                                                                        </Group>
                                                                        <Textarea
                                                                            withAsterisk
                                                                            label="Address"
                                                                            {...form.getInputProps(
                                                                                "address"
                                                                            )}
                                                                        />
                                                                        <ImageInput
                                                                            importImages={importImages}
                                                                            setImportImages={setImportImages}
                                                                            files={files}
                                                                            setFiles={setFiles}
                                                                            base64Preview={base64Preview}
                                                                            setBase64Preview={setBase64Preview}
                                                                            imagesError={imagesError}
                                                                        />
                                                                        <VideoEmbedInput
                                                                            urlArr={urlArr}
                                                                            setUrlArr={setUrlArr}
                                                                        />
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid.Col>
                                                        </Grid>
                                                    </Stack>
                                                </Card>

                                            </Grid.Col>
                                        </Grid>
                                    </Grid.Col>
                                )}
                                {step === OFFER_STEPS.PREVIEW && (
                                    <>
                                        {/* <Grid.Col md={12}>
                                            <Divider />
                                        </Grid.Col> */}
                                        <Grid.Col md={7}>
                                            <Stack pt={16}>
                                                <Group sx={{ width: '100%' }} position="apart">
                                                    <Text fw={700} fz={24}>Offer Preview</Text>
                                                    <Button onClick={() => handleEdit()}>Edit</Button>
                                                </Group>
                                                <Card sx={{ border: "1px solid #BFC8C3" }}>
                                                    <Stack>
                                                        <Text
                                                            fz={18}
                                                            fw={700}
                                                        >
                                                            Property Details
                                                        </Text>
                                                        <Stack>{translateToTags()}</Stack>
                                                        <Divider my={8} />
                                                        <Text
                                                            fz={18}
                                                            fw={700}
                                                        >
                                                            Offer Details
                                                        </Text>
                                                        <Grid>
                                                            <Grid.Col sm={12}>
                                                                <Stack spacing={2}>
                                                                    <Text fw={700}>
                                                                        Address
                                                                    </Text>
                                                                    <Text>
                                                                        {form.values.address}
                                                                    </Text>
                                                                </Stack>
                                                            </Grid.Col>
                                                            <Grid.Col sm={12}>
                                                                <Stack spacing={2}>
                                                                    <Text fw={700}>Price</Text>
                                                                    <Text>
                                                                        RM{" "}
                                                                        {form.values.price.toLocaleString()}
                                                                    </Text>
                                                                </Stack>
                                                            </Grid.Col>
                                                            {blocks.map((b: any) => (
                                                                <Grid.Col sm={3}>
                                                                    <Group spacing={2}>
                                                                        <Text fw={700}>
                                                                            {b.title}:{" "}
                                                                        </Text>
                                                                        <Text>{b.key}</Text>
                                                                    </Group>
                                                                </Grid.Col>
                                                            ))}
                                                            <Grid.Col sm={12}>
                                                                <Stack spacing={2}>
                                                                    <Text fw={700}>Images</Text>
                                                                    <Group>
                                                                        <Indicator
                                                                            inline
                                                                            label={`+ ${mergePreviews().length -
                                                                                1
                                                                                }`}
                                                                            disabled={
                                                                                mergePreviews().length <=
                                                                                1
                                                                            }
                                                                            size={16}
                                                                        >
                                                                            <Tooltip
                                                                                label="Click to view"
                                                                                position="right"
                                                                            >
                                                                                <Center
                                                                                    sx={{
                                                                                        position:
                                                                                            "relative",
                                                                                        cursor: "pointer",
                                                                                        border: "1px #dbdbdb solid",
                                                                                        borderRadius: 8,
                                                                                        width: "auto",
                                                                                    }}
                                                                                    w={120}
                                                                                    h={120}
                                                                                    onClick={() => setShowLightbox(true)}
                                                                                >
                                                                                    <Image
                                                                                        width={"100%"}
                                                                                        height={"100%"}
                                                                                        fit="contain"
                                                                                        src={mergePreviews()[0].url}
                                                                                    />
                                                                                </Center>
                                                                            </Tooltip>
                                                                        </Indicator>
                                                                    </Group>
                                                                </Stack>
                                                            </Grid.Col>
                                                            <Grid.Col sm={12}>
                                                                <Stack spacing={4} sx={{ width: '100%' }}>

                                                                    <Textarea
                                                                        label="Offer Pitch"
                                                                        description="Describe how your property offer fits the requirement of the requester"
                                                                        {...form.getInputProps("pitch")}
                                                                        error={form.values.pitch.length < 20 ?? "Please input at least 20 characters"}
                                                                    >

                                                                    </Textarea>
                                                                    <Group sx={{ width: '100%' }} position="right">
                                                                        <Text size={'xs'}>
                                                                            {form.values.pitch.length}/500
                                                                        </Text>
                                                                    </Group>
                                                                </Stack>
                                                            </Grid.Col>
                                                            <Grid.Col sm={12}>
                                                                <Checkbox
                                                                    label="I confirm that I have thoroughly reviewed all the information pertaining to this offer."
                                                                    checked={offerCheck}
                                                                    onChange={(event) =>
                                                                        setOfferCheck(
                                                                            event.currentTarget
                                                                                .checked
                                                                        )
                                                                    }
                                                                />
                                                            </Grid.Col>
                                                        </Grid>
                                                    </Stack>
                                                </Card>
                                            </Stack>
                                        </Grid.Col>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </Stack>
            </Container>
            <Box
                sx={{
                    width: "100vw",
                    position: "sticky",
                    bottom: 0,
                    left: 0,
                    background: "white",
                    boxShadow: `0px -8px 16px 0px rgba(0,0,0,0.2)`,
                    zIndex: 100,
                }}
                p={16}
            >
                <Container
                    size="1200px"
                    p={0}
                >
                    <Flex
                        sx={{ flexDirection: 'row', justifyContent: 'flex-end' }}
                    >
                        {/* <Button variant="outline">Cancel</Button> */}
                        {step === OFFER_STEPS.FORM && (
                            <Group>
                                <Button
                                    // disabled={!isReadyForPreview}
                                    onClick={() => validateForm()}
                                >
                                    Next
                                </Button>
                            </Group>
                        )}
                        {step === OFFER_STEPS.PREVIEW && (
                            <Button
                                onClick={() => handleCreate()}
                                disabled={!offerCheck || createLoading || form.values.pitch.length < 20}
                                variant="outline"
                            >
                                <Group spacing={8}>
                                    Make Offer
                                    {/* <Group spacing={4} align="center">
                                        <Text>2</Text>
                                        <Image
                                            src={"/token.png"}
                                            height={16}
                                            width={16}
                                            style={{ marginRight: "4px" }}
                                        />
                                    </Group> */}
                                </Group>
                                {/* {selectedExistingListing ? "Pay 1 token to Make Offer" : "Pay 5 tokens to Make Offer"} */}
                            </Button>
                        )}
                    </Flex>
                </Container>
            </Box>
        </Box>
    );
}
