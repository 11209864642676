import { async } from "@firebase/util";
import { SelectItem } from "@mantine/core";
import { seriousnessRatingOptions } from "./constants";

export const getReadableValueFromSelect = (arr: any[], key: string) => {
    const display = arr.find((a) => a.value === key);
    return display?.label ?? "";
};

export const multiSelectFilter = (
    value: string,
    selected: boolean,
    item: any
) => {
    if (!value) {
        return true;
    }

    if (!!item.label && !!item.value) {
        return (
            item.label.toLowerCase().includes(value.toLowerCase()) ||
            item.value.toLowerCase().includes(value.toLowerCase())
        );
    }

    return false;
};

export const selectFilter = (
    value: string,
    item: {
        label: string;
        value: string;
    }
) => {
    if (!value) {
        return true;
    }

    if (!!item.label && !!item.value) {
        return (
            `${item.label}`.toLowerCase().includes(value.toLowerCase()) ||
            `${item.value}`.toLowerCase().includes(value.toLowerCase())
        );
    }

    return false;
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
    let formattedIdentifier = phoneNumber;
    const regex = /[ -]/g;
    const startString = /^(60|0)/g;

    formattedIdentifier = phoneNumber
        .replace(startString, "")
        .replace(regex, "");

    return formattedIdentifier;
};

function imageSize(url: string) {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
        img.onload = () => {
            // Natural size is the actual image size regardless of rendering.
            // The 'normal' `width`/`height` are for the **rendered** size.
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            // Resolve promise with the width and height
            resolve({ width, height });
        };

        // Reject promise on error
        img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
}

export const convertFileToPreview = async (files: File[]) => {
    const convertFileToBase64 = (file: Blob): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    const previewArr = [] as any[];
    const fileArr = [] as File[];
    for (const file of files) {
        const base64: string | unknown = await convertFileToBase64(file);
        const size = await imageSize(base64 as string);
        previewArr.push({
            base64,
            name: file.name,
            size,
        });

        fileArr.push(file);
    }

    return {
        previewArr,
        fileArr,
    };
};

export const dataUrlToFile = async (
    dataUrl: string,
    fileName: string
): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
};

export const getTagDisplayText = (tag: any, value: string) => {
    const displayText = tag.values.options.find((o: any) => o.value === value);

    if (!displayText) {
        return "";
    }

    return displayText.label;
};

export const formatEmbedObj = (urlArr: any) => {
    return urlArr.map((u: any) => {
        let obj = {
            link: u.link,
            type: u.type,
            thumbnail: u.thumbnail,
        } as any;

        if (!!u.id) {
            obj.id = u.id;
        }

        return obj;
    });
};

export const censorString = (inputString: string) => {
    if(!inputString){
        return ""
    }

    if (inputString.length <= 4) {
        // If the input is not a string or is shorter than 4 characters, return as is
        return inputString;
    }

    const censoredPart = "*".repeat(inputString.length - 4);
    const originalPart = inputString.substring(0, 4);

    return originalPart + censoredPart;
};

export const formatUnknownStateArea = (state: string, area: string) => {
    if (!!area && !!state) {
        return `${area}, ${state}`;
    }

    return state || area;
};

export function mergeArraysAndRemoveDuplicates<T>(arrays: T[][]): T[] {
    // Merge all arrays into one using the concat method
    const mergedArray: T[] = arrays.reduce(
        (acc, currentArray) => acc.concat(currentArray),
        []
    );

    // Use the Set object to remove duplicates
    const uniqueArray: T[] = Array.from(new Set(mergedArray));

    return uniqueArray;
}

export const formatSeriousness = (id: number) => {
    return seriousnessRatingOptions.find((s) => parseInt(s.value) === id);
};


export const formatListingType = (type: string) => {
    if(type !== "BUY"){
        return type
    }

    return "SALE"
}