import React, { useState, useEffect, useContext, useMemo } from "react";
import {
    Box,
    Container,
    Text,
    Button,
    Group,
    Card,
    Stack,
    Divider,
    Loader,
    Center,
    Image,
} from "@mantine/core";
import { UserContext } from "context/user/userContext";
import { Permission, useAuth } from "hooks/useAuth";
import { useQuery } from "react-query";
import { accountTypes } from "types/accountTypes";
import { USER_ACTIONS } from "context/user/userActions";
import { AgentProfile } from "../components/Profile/AgentProfile";
import { ListerProfile } from "../components/Profile/ListerProfile";
import { useNavigate } from "react-router-dom";
import { AgentDetail, BaseUser } from "types/userTypes";
import { useProfileController } from "hooks/useProfile";
import { AlertTriangle } from "tabler-icons-react";
import FeedbackBanner from "components/Profile/FeedbackBanner";
import PendingVerificationBanner from "components/Banners/PendingVerificationBanner";
import { useCanOffer } from "hooks/useCanOffer";
import AgentDashboardv2 from "./AgentDashboard/components/DashboardV2";
import CustomFooter from "components/Footer/Footer";
import PageLoader from "components/LoadingStates/PageLoader";

export const Profile = (props: any) => {
    const { canOffer, canOfferisLoading } = useCanOffer()

    const { view } = useProfileController();
    const { user, userDispatch } = useAuth(Permission.USER_ONLY);
    const navigate = useNavigate();

    if (!view) {
        return <>
            <PageLoader title="Agent Dashboard" />
        </>
    }
    return (
        <>
            {view === accountTypes.AGENT ? (
                <>
                    <AgentDashboardv2 {...props} />
                </>
            ) : (
                <Box
                    sx={
                        (theme) => ({
                            // height: "100%",
                            backgroundColor: theme.colors.bgYellow,
                        })
                    }
                >
                    <Container size="1200px">
                        {/* {!canOffer && !canOfferisLoading && (
                            <PendingVerificationBanner />
                        )} */}
                        <Stack>
                            <ListerProfile />
                        </Stack>
                    </Container >
                </Box >
            )}
            {view !== accountTypes.AGENT && (
                <CustomFooter />
            )}
        </>
    );
};
