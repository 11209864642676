import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { USER_TYPE } from 'helpers/constants';
import { Permission, useAuth } from 'hooks/useAuth';
import usePostLoginFunctions from 'hooks/usePostLoginFunctions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const ContinueDraftModal = ({ opened, close, draftData }: any) => {
    // const [opened, setOpened] = useState(false)
    const { user, logout, profileData } = useAuth(Permission.ANYONE);
    const { createPropRequest, createAgentRequest } = usePostLoginFunctions();

    // useEffect(() => {
    //     if (!!user && !!user.jwt) {
    //         const hasPostLoginAction = sessionStorage.getItem("pre_login_draft");

    //         if (!!hasPostLoginAction && user.userType === USER_TYPE.LISTER) {

    //             let draft = JSON.parse(hasPostLoginAction)
    //             console.log({ draft })
    //             setDraftData(draft)
    //         }
    //     }
    // }, [user])

    const handleContinue = async () => {
        if (!draftData) {
            close()
            return;
        }

        const { data, action } = draftData

        switch (action) {
            case "CREATE_PROP_REQUEST":
                await createPropRequest(user, data)
                break;
            case "CREATE_AGENT_REQUEST":
                await createAgentRequest(user, data)
                break;
        }

        close()
        sessionStorage.removeItem("pre_login_draft");
    }

    const handleCancel = () => {
        close()
        sessionStorage.removeItem("pre_login_draft");
    }

    const title = useMemo(() => {
        if (!!draftData && !!draftData?.action) {
            switch (draftData?.action) {
                case "CREATE_PROP_REQUEST":
                    return "property request"
                case "CREATE_AGENT_REQUEST":
                    return "agent request"
            }
        }
    }, [draftData])

    return (
        <Modal
            centered
            opened={opened}
            onClose={() => { }}
            withCloseButton={false}
            closeOnClickOutside={false}
        >
            <Stack>
                <Text>Continue with creating your {title}?</Text>

                <Group w="100%" grow align='top'>
                    <Stack spacing={4}>
                        <Button variant='light' color="red" onClick={() => handleCancel()}>Cancel</Button>
                        <Text color="red" fz={12} align='center'>Draft will be deleted</Text>
                    </Stack>
                    <Button onClick={() => handleContinue()}>OK</Button>
                </Group>
            </Stack>

        </Modal>
    );
};

export default ContinueDraftModal;